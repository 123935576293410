import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import styled from "styled-components"
import Zoom from "react-medium-image-zoom"
import SEO from "../components/seo"
import "../style/index.css"

import footerBg from "../images/footer-bg.png"
import AboutPage from "./about-us"

const Div = styled.div`
  width: 95%;
  margin: 0px auto;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  .image-box {
    display: flex;
    flex-direction: column;
    tex-align: center;
    margin: 10px;
  }
  .image-box video {
    width: 300px;
    height: auto;
  }
`

const VideoDiv = styled.div`
  width: 95%;
  margin: 0px auto;
  .video-thumb {
    padding: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    overflow-x: scroll;
    video {
      width: auto;
      height: 400px;
      margin: 2px;
    }
  }
`

const data = [
  {
    title: "Hi hello",
    src: "/company/gajurmukhi.jpg",
  },
  {
    title: "Hi hello",
    src: "/company/rosin.jpg",
  },
  {
    title: "Hi hello",
    src: "/company/rosin1.jpg",
  },
  {
    title: "Hi hello",
    src: "/company/kasthamandal.jpg",
  },
  {
    title: "Hi hello",
    src: "/company/kasthamandal1.jpg",
  },
  {
    title: "Hi hello",
    src: "/company/everest.jpg",
  },
  {
    title: "Video-1",
    type: "video",
    src: "/gallery/videos/00001.MP4",
  },
  {
    title: "Video-2",
    type: "video",
    src: "/gallery/videos/00002.MP4",
  },
  {
    title: "Video-3",
    type: "video",
    src: "/gallery/videos/00003.MP4",
  },
  {
    title: "Video-3",
    type: "video",
    src: "/gallery/videos/00003.MP4",
  },
  {
    title: "Video-4",
    type: "video",
    src: "/gallery/videos/00004.MP4",
  },
  {
    title: "Video-5",
    type: "video",
    src: "/gallery/videos/00005.MP4",
  },
  {
    title: "Video-6",
    type: "video",
    src: "/gallery/videos/00006.MP4",
  },
  {
    title: "Video-7",
    type: "video",
    src: "/gallery/videos/00007.MP4",
  },
  {
    title: "Video-8",
    type: "video",
    src: "/gallery/videos/00008.MP4",
  },
]

const GalleryPage = () => {
  const [currentVideo, setCurrentVideo] = useState(false)
  return (
    <Layout showOnlyHome={true}>
      <SEO title="Gallery" />
      <div style={{
          overflowX: 'auto',
          display: 'flex',
        }}>
          {
            data.map(item => item.type === 'video' && (
                <div className="image-box" style={{display: 'inline', margin: '0 5px'}}>
                  <video src={item.src} controls/>
                </div>
            ))
          }
        </div>
      <Div>
        {data.map(item => (
          <>
            {!item.type && (
              <div className="image-box">
                <Zoom>
                  <img src={item.src} />
                </Zoom>
                {/* <b>{item.title}</b> */}
              </div>
            )}
          </>
        ))}
        <Zoom></Zoom>
      </Div>
    </Layout>
  )
}

export default GalleryPage
